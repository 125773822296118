import { createBrowserRouter } from "react-router-dom";

import DriverHome from "./screens/driver-home";
import AdminLocationView from "./screens/admin-location-view";
import AdminBookingCards from "./screens/admin-booking-cards";
import AvailableCubbis from "./screens/available-cubbis";
import AdminDoorDashById from "./screens/admin-doordash-by-id";
import AdminPickupById from "./screens/admin-pickup-by-id";
import AdminPickups from "./screens/admin-pickups";
import DriverDoorDash from "./screens/driver-doordash";
import DriverDoorDashById from "./screens/driver-doordash-by-id";
import DriverPickups from "./screens/driver-pickups";
import DriverPickupById from "./screens/driver-pickups-by-id";

const DRIVER_ROUTER = createBrowserRouter([
  {
    path: "/",
    element: <DriverHome />,
    loader: () => {
      console.log("loading driverr");
      return null;
    },
  },
  {
    path: "/locations",
    element: <DriverHome />,
  },
  {
    path: "/locations/:id",
    element: <AdminLocationView />,
  },
  {
    path: "/locations/:id/booking-cards",
    element: <AdminBookingCards />,
  },
  {
    path: "/booking-reassign",
    element: <AvailableCubbis />,
  },
  {
    path: "/doordash",
    element: <DriverDoorDash />,
  },
  {
    path: "/doordash/:id",
    element: <DriverDoorDashById />,
  },
  {
    path: "/pickups",
    element: <DriverPickups />,
  },
  {
    path: "/pickups/:id",
    element: <DriverPickupById />,
  },
]);

export default DRIVER_ROUTER;
